import { Token } from "myswap2-sdk"
import { WrappedTokenInfo } from "../state/lists/hooks"

import { getAddress } from "@ethersproject/address";

let sourceData = [
    {
        "name": "USDT",
        "address": getAddress("0xffCcf0f2a9DE309B69dB326C01742f6540cbF450"),
        "symbol": "USDT",
        "decimals": 18,
        "chainId": 12306,
        "logoURI": "https://v2.starswap.info/images/0xffccf0f2a9de309b69db326c01742f6540cbf450.png",
        "tags": [
            "defi"
        ]
    },
    {
        "name": "Wrapped Fibo",
        "address": getAddress("0x1a714b3fcfe588010154f9ff854b0f58bed6ae72"),
        "symbol": "WFIBO",
        "decimals": 18,
        "chainId": 12306,
        "logoURI": "https://v2.starswap.info/images/0x1a714b3fcfe588010154f9ff854b0f58bed6ae72.png",
        "tags": [
            "defi"
        ]
    }
]

let data: { [address: string]: Token } = {};

export default (): { [address: string]: Token } => {
    sourceData.forEach(item => {
        data[item.address] = ((new WrappedTokenInfo(item, [])) as Token);
    })
    return data;
}